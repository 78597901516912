import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { IOccurrence } from '../../../../@types/model/occurrence';
import moment from 'moment';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import occurrenceFunctions from '../../../../store/occurrence/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { dispatch } from '../../../../store';
import occurrenceActions from '../../../../store/occurrence/actions';
import Tooltip from '@material-ui/core/Tooltip';
import ReactToPrint from 'react-to-print';
import Icon from '@material-ui/core/Icon';
import generalFunctions from '../../../../store/general/functions';
import { setPageTitle, resetPageTitle } from '../../../../service/helper/functionHelperService';
import { RIGHTS } from '../../../../appConstants';
import OccurrenceEditDialog from './OccurrenceEditDialog';
import { IDivision } from '../../../../@types/model/division';

interface IOccurrenceDialogProps {
    onClose? : () => void;
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    occurrence? : IOccurrence;
    guid? : string;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    enableEdit : boolean;
}

interface IOccurrenceDialogState {
    isLoading : boolean;
}

class OccurrenceDialog extends React.Component<IOccurrenceDialogProps, IOccurrenceDialogState> {
    private componentRef : any;
    constructor(props : IOccurrenceDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : IOccurrenceDialogProps, prevState : IOccurrenceDialogState) => {
        if (prevProps.guid !== this.props.guid) {
            if (this.props.guid) {
                this.loadOccurrence();
            }
        }
    }

    private loadOccurrence = async () => {
        this.setState({
            isLoading: true,
        });

        await occurrenceFunctions.get(undefined, this.props.guid ?? this.props.occurrence?.guid);

        if (this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    public onClose = () => {
        this.setState({
            isLoading: false,
        });
        dispatch(occurrenceActions.setOccurrence());

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    public render = () => {
        const { isLoading } = this.state;
        const { occurrence, fullScreen, transition, guid, enableEdit } = this.props;

        return (
            <Dialog
                    open={!!occurrence || !!guid}
                    onClose={this.onClose}
                    aria-labelledby='occurrence-info-dialog-title'
                    aria-describedby='occurrence-info-description'
                    fullWidth={this.props.fullWidth}
                    maxWidth={this.props.maxWidth}
                    fullScreen={fullScreen}
                    TransitionComponent={transition}
                    transitionDuration={500}
                >
                    <div
                    ref={el => (this.componentRef = el)}>
                        <AppBar className='posr'>
                            <Toolbar>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <CloseIcon />
                                </IconButton>
                                {
                                    occurrence &&
                                    <Typography variant='h6' color='inherit'>
                                        {occurrence.number} - {moment.utc(occurrence.date).format('YYYY/MM/DD')}
                                    </Typography>
                                }
                                <Tooltip title='Refresh'>
                                    <div>
                                        <IconButton onClick={this.loadOccurrence} disabled={isLoading} color='inherit'>
                                            <Icon>
                                                refresh
                                            </Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <span className='flx1' />
                                {
                                    enableEdit &&
                                    occurrence &&
                                    <OccurrenceEditDialog
                                        occurrence={occurrence}
                                        maxWidth='md'
                                        fullWidth
                                    />
                                }
                                {
                                    occurrence &&
                                    <ReactToPrint
                                        trigger={() =>
                                            <div>
                                                <Tooltip title='Print'>
                                                    <IconButton color='inherit'>
                                                        <Icon>
                                                            print
                                                        </Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                        onPrintError={generalFunctions.showPrintError}
                                        content={() => this.componentRef}
                                        onBeforePrint={() => setPageTitle(`${occurrence.number}`)}
                                        onAfterPrint={resetPageTitle}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <div className='p15'>
                                {
                                    !isLoading && occurrence &&
                                    <div className='fdc'>
                                        <div className='fdr aic mb15 cpd'>
                                            <Typography variant='h5' className='fw500'>USER INFO</Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Department:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { occurrence.division?.dept ? occurrence.division?.dept : ''}
                                            </Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Created By:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { occurrence.createdByName.toTitleCase() }
                                            </Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Updated By:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { occurrence.updatedByName.toTitleCase() }
                                            </Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='body1' component='span' style={{ width: 150, fontWeight: 'bold' }}>
                                                Incident:
                                            </Typography>
                                            <Typography variant='body1' component='span'>
                                                { occurrence.incident ? "Yes" : "No" }
                                            </Typography>
                                        </div>
                                        <div className='fdr' style={{ paddingBottom: 10 }}>
                                            <Typography variant='h5' className='fw500'>DESCRIPTION</Typography>
                                        </div>
                                        <Typography variant='body1' component='p'>
                                            {
                                                occurrence.description
                                            }
                                        </Typography>
                                    </div>
                                }
                                {
                                    isLoading &&
                                    <div className='flx1 fdc aic'>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        occurrence: state.occurrence.selectedOccurrence,
        enableEdit: !!state.auth.session?.user.userRights.some(x => x.rightId === RIGHTS.OCC_EDIT),
    };
};

export default connect(
    mapStateToProps,
)(OccurrenceDialog);
